import { useState } from 'react'
import { ScanDeviceInfo } from 'src/api/scanner'
import {
  JsonView,
  darkStyles,
  defaultStyles,
  collapseAllNested,
} from 'react-json-view-lite'
import 'react-json-view-lite/dist/index.css'
import { emitKeypressEvents } from 'readline'

interface MBusScannerResultRowProps {
  devInfo: ScanDeviceInfo
}

export const MBusScannerResultRow: React.FC<MBusScannerResultRowProps> = ({
  devInfo,
}) => {
  const [showDetails, setShowDetails] = useState(false)
  const [showRaw, setShowRaw] = useState(false)
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
  navigator.clipboard.writeText(devInfo.rawFrame).then(() => {
    setCopied(true);
    setTimeout(() => setCopied(false), 1500); // Hide after 1.5s
  });
};

  // id formater
  const idFormat = (num: number): string => {
    const str = num.toString()
    if(str.length <= 8)
    {
      return '0'.repeat(8 - str.length) + str
    }
    else
    {
      return str
    }
  }

  // format helper
  const toHex = (num: number, decimals: number): string => {
    let hexStr = num.toString(16).toUpperCase()
    return '0'.repeat(decimals - hexStr.length) + hexStr
  }

  if (devInfo.rssi != undefined) { // if has RSSI, then is wireless..

    return (
      <tbody className="table-group-divider">
        <tr>
          {devInfo.time && <td className="text-end align-middle">{devInfo.time.toLocaleTimeString(undefined, { hour12: false })}</td>}
          <td className="text-end align-middle">{idFormat(devInfo.id)}</td>
          <td className="text-end align-middle">
            {devInfo.manStr} ({toHex(devInfo.manNum, 4)})
          </td>
          <td className="text-end align-middle">{"0x"+toHex(devInfo.devType, 2) }</td>
          <td className="text-end align-middle">{devInfo.mode}</td>
          <td className="text-end align-middle">{devInfo.rssi}</td>
          <td className="text-center">
            {devInfo.rawFrame != undefined && (
              <button
                type="button"
                className={`btn btn-outline-${showRaw ? 'primary' : 'secondary'} btn-sm mx-1`}
                onClick={() => setShowRaw((prev) => !prev)}
              >
                {showRaw ? 'Hide Raw' : 'Show Raw'}
              </button>
            )}
          </td>
          <td className="text-center">
            {devInfo.rawFrame != undefined && (
              <button
                type="button"
                className={`btn btn-outline-${showDetails ? 'primary' : 'secondary'} btn-sm mx-1`}
                onClick={() => setShowDetails((prev) => !prev)}
              >
                {showDetails ? 'Hide Parsed' : 'Show Parsed'}
              </button>
            )}
          </td>
        </tr>
  
        {showRaw && (
          <tr>
            <td
              colSpan={7}
              style={{ whiteSpace: 'normal', wordBreak: 'break-word', cursor: 'pointer' }}
              onClick={handleCopy}
              title="Click to copy"
            >
              {devInfo.rawFrame}
              {copied && <span style={{ marginLeft: 8, color: 'green' }}>Copied!</span>}
            </td>
          </tr>
        )}
  
        {showDetails && (
          <tr>
            {devInfo.parsedFrame !== undefined && (
              <td colSpan={7}>
                <JsonView
                  data={devInfo.parsedFrame}
                  shouldExpandNode={collapseAllNested}
                  clickToExpandNode={true}
                  style={darkStyles}
                />
              </td>
            )}
            {devInfo.parsedFrame === undefined && (
              <td colSpan={7}>
                Response corrupted. Try parsing using{' '}
                <a
                  target="_blank"
                  href="https://backend.wmbus.acrios.com/#/Parsers/dummyWmbusMetersParse_parser_wmbusmeters_hex_get"
                >
                  wM-Bus Parser
                </a>
                .
              </td>
            )}
          </tr>
        )}
      </tbody>
    )


  }
  else {
    return (
      <tbody className="table-group-divider">
        <tr>
          <td className="align-middle">
            {devInfo.primaryAddr !== undefined && (
              <>
                {devInfo.primaryAddr}
                <sub>
                  <small className="text-muted">(10)</small>
                </sub>
              </>
            )}
          </td>
          <td className="text-end align-middle">{idFormat(devInfo.id)}</td>
          <td className="text-end align-middle">
            {devInfo.manStr} ({toHex(devInfo.manNum, 4)})
          </td>
          <td className="text-end align-middle">{toHex(devInfo.ver, 2)}</td>
          <td className="text-end align-middle">{toHex(devInfo.medium, 2)}</td>
          <td className="text-center">
            {devInfo.rawFrame != undefined && (
              <button
                type="button"
                className={`btn btn-outline-${showRaw ? 'primary' : 'secondary'} btn-sm mx-1`}
                onClick={() => setShowRaw((prev) => !prev)}
              >
                {showRaw ? 'Hide Raw' : 'Show Raw'}
              </button>
            )}
          </td>
          <td className="text-center">
            {devInfo.rawFrame != undefined && (
              <button
                type="button"
                className={`btn btn-outline-${showDetails ? 'primary' : 'secondary'} btn-sm mx-1`}
                onClick={() => setShowDetails((prev) => !prev)}
              >
                {showDetails ? 'Hide Parsed' : 'Show Parsed'}
              </button>
            )}
          </td>
        </tr>
  
        {showRaw && (
          <tr>
            <td
              colSpan={7}
              style={{ whiteSpace: 'normal', wordBreak: 'break-word', cursor: 'pointer' }}
              onClick={handleCopy}
              title="Click to copy"
            >
              {devInfo.rawFrame}
              {copied && <span style={{ marginLeft: 8, color: 'green' }}>Copied!</span>}
            </td>
          </tr>
        )}
  
        {showDetails && (
          <tr>
            {devInfo.parsedFrame !== undefined && (
              <td colSpan={7}>
                <JsonView
                  data={devInfo.parsedFrame}
                  shouldExpandNode={collapseAllNested}
                  clickToExpandNode={true}
                  style={darkStyles}
                />
              </td>
            )}
            {devInfo.parsedFrame === undefined && (
              <td colSpan={7}>
                Response corrupted. Try parsing using{' '}
                <a
                  target="_blank"
                  href="https://backend.wmbus.acrios.com/#/Parsers/parse_provided_input_as_hex_mbus_parser_mbus_hex_get"
                >
                  M-Bus Parser
                </a>
                .
              </td>
            )}
          </tr>
        )}
      </tbody>
    )

  }

}
