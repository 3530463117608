// src/api/wmbusmeters/wmbusmeters_wrapper.js

let cachedWasmBinary = null;

const preloadWasmModule = async () => {
  if (!cachedWasmBinary) {
    const wasmPath = '/wmbusmeters/wmbusmeters.wasm';
    const response = await fetch(wasmPath);
    cachedWasmBinary = await response.arrayBuffer();
  }
};

const wmbusmetersParser = async (input) => {
  if (!cachedWasmBinary) {
    await preloadWasmModule();
  }

  const outputLines = [];

  const Module = await wmbusmeters_createModule({
    arguments: ["-analyze", input],
    locateFile: (path) => {
      const blob = new Blob([cachedWasmBinary], { type: "application/wasm" });
      return URL.createObjectURL(blob);
    },
    print: (text) => outputLines.push(text),
    printErr: (text) => {
      throw new Error(text);
    },
    noExitRuntime: false,
  });

  return outputLines.join("\n");
};

export { preloadWasmModule, wmbusmetersParser };
