import { useContext, useMemo, useState } from 'react'
import {
  AccordionContext,
  AccordionHeader,
  AccordionItem,
  useAccordionButton,
} from 'react-bootstrap'
import AccordionBody from 'react-bootstrap/esm/AccordionBody'
import { ScanDeviceInfo } from 'src/api/scanner'
import { MBusScannerResultRow } from './MBusScannerResultRow'
import { AddressingType } from './MBusScannerModal'

export enum MBusScannerResultMsgLvl {
  INFO = 'INFO',
  WARNING = 'WARNING',
  ERROR = 'ERROR',
}

export interface MBusScannerResultMsg {
  level: MBusScannerResultMsgLvl
  msg: string
}

interface MBusScannerResultsProps {
  scanType: AddressingType
  msgs: MBusScannerResultMsg[]
  description: string
  devices: ScanDeviceInfo[]
  accordionEventKey: string
  toggleHandler: (key: string) => void
}

export const MBusScannerResults: React.FC<MBusScannerResultsProps> = ({
  scanType,
  msgs,
  description,
  devices,
  accordionEventKey,
  toggleHandler,
}) => {
  const [sortedBy, setSortedBy] = useState("Time")
  const [sortAsc, setSortAsc] = useState(true)

  const level2color = (lvl: MBusScannerResultMsgLvl) => {
    switch (lvl) {
      case MBusScannerResultMsgLvl.INFO:
        return 'info'
      case MBusScannerResultMsgLvl.WARNING:
        return 'warning'
      case MBusScannerResultMsgLvl.ERROR:
        return 'danger'
      default:
        return 'light'
    }
  }

  const decoratedOnClick = useAccordionButton(accordionEventKey, () => {
    console.log(`${accordionEventKey} CLICKED`)
    toggleHandler(accordionEventKey)
  })

  const handleChangeSortBy = (colName: string) => (e) => {
    if (sortedBy != colName) {
      setSortedBy(colName);
    } else {
      setSortAsc(prev => !prev)
    }
  }

  const sortDevices = (
    devices: ScanDeviceInfo[], 
    sortedBy: string, 
    sortAsc: boolean
  ): ScanDeviceInfo[] => {
    return devices.sort((a, b) => {
      let comparison = 0;
      
      switch (sortedBy) {
        case 'Time':
          comparison = a.time && b.time 
            ? a.time.valueOf() - b.time.valueOf()
            : 0;
          break;
        case 'ID':
          comparison = a.id - b.id;
          break;
        case 'ManID':
          comparison = a.manNum - b.manNum;
          break;
        case 'DevType':
          comparison = a.devType - b.devType;
          break;
        case 'Mode':
          comparison = a.mode.localeCompare(b.mode);
          break;
        case 'RSSI':
          comparison = a.rssi - b.rssi;
          break;
        default:
          comparison = 0;
      }
      
      return sortAsc ? comparison : -comparison;
    });
  };
  
// Filter state management
interface DeviceFilterState {
  Time?: string;
  ID?: string;
  ManID?: string;
  DevType?: string;
  Mode?: string;
  RSSI?: string;
}

const [filters, setFilters] = useState<DeviceFilterState>({});

// Filter handling function
const handleChangeFilter = (columnId: keyof DeviceFilterState) => (e: React.ChangeEvent<HTMLInputElement>) => {
  setFilters(prev => ({
    ...prev,
    [columnId]: e.target.value
  }));
};

// Filter implementation
const filterDevices = (
  devices: ScanDeviceInfo[],
  filters: DeviceFilterState
): ScanDeviceInfo[] => {
  return devices.filter(device => {
    let matchesAllFilters = true;
    
    // Time filtering
    if (filters.Time && device.time) {
      const filterTime = filters.Time.replace('*', '');
      const deviceTimeStr = device.time.toLocaleString();
      matchesAllFilters = deviceTimeStr.startsWith(filterTime);
    }
    
    // ID filtering
    if (filters.ID) {
      const idFilter = filters.ID.replace('*', '');
      matchesAllFilters = matchesAllFilters && String(device.id).startsWith(idFilter);
    }
    
    // ManID filtering
    if (filters.ManID) {
      const manIdFilter = filters.ManID.replace('*', '');
      matchesAllFilters = matchesAllFilters && String(device.manNum).startsWith(manIdFilter);
    }
    
    // DevType filtering
    if (filters.DevType) {
      const devTypeFilter = filters.DevType.replace('*', '');
      matchesAllFilters = matchesAllFilters && String(device.devType).startsWith(devTypeFilter);
    }
    
    // Mode filtering
    if (filters.Mode) {
      const modeFilter = filters.Mode.replace('*', '');
      matchesAllFilters = matchesAllFilters && device.mode.startsWith(modeFilter);
    }
    
    // RSSI filtering
    if (filters.RSSI) {
      const rssiFilter = filters.RSSI.replace('*', '');
      matchesAllFilters = matchesAllFilters && String(device.rssi).startsWith(rssiFilter);
    }
    
    return matchesAllFilters;
  });
};


  // Usage in a React component
  const devicesSorted = useMemo(() => {

    // first sort
    const sortedDevices = sortDevices(devices, sortedBy, sortAsc);

    // Then apply filters to the sorted result
    return filterDevices(sortedDevices, filters);

  }, [sortedBy, sortAsc, devices?.length]);


  if([AddressingType.BUP433_MODE, AddressingType.BUP868_MODE, AddressingType.TC_MODE, AddressingType.S_MODE, AddressingType.M_MODE, AddressingType.SENSUS434_MODE].includes(scanType))
  {
    return (
      <AccordionItem eventKey={accordionEventKey}>
        <AccordionHeader onClick={decoratedOnClick}>
          <span className="text-primary-emphasis">{description}</span>
        </AccordionHeader>
        <AccordionBody>
          <div>
            {msgs.map((msg, idx) => (
              <div className={`text-${level2color(msg.level)}`} key={idx}>
                [{msg.level}] {msg.msg}
              </div>
            ))}
          </div>
          {devices.length > 0 && (
            <table className="table table-sm mt-1">
              <thead>
                <tr>
                  {['Time', 'ID', 'ManID', 'DevType', 'Mode', 'RSSI'].map(
                    (colName, idx) => (
                      <th
                        scope="col"
                        key={idx}
                        className={idx != 0 ? 'text-end' : ''}
                        onClick={handleChangeSortBy(colName)}
                      >
                        {colName} {(sortedBy == colName) && <span>{sortAsc ? "▲" : "▼"}</span>}
                      </th>
                    )
                  )}
                  {['Raw', 'Parsed'].map((btnColName, idx) => (
                    <th scope="col" key={idx} className="text-center">
                      {btnColName}
                    </th>
                  ))}
                </tr>
              </thead>
              {devices.map((devInfo, idx) => (
                <MBusScannerResultRow devInfo={devInfo} key={idx} />
              ))}
            </table>
          )}
        </AccordionBody>
      </AccordionItem>
    )
  }
  else {
    return (
      <AccordionItem eventKey={accordionEventKey}>
        <AccordionHeader onClick={decoratedOnClick}>
          <span className="text-primary-emphasis">{description}</span>
        </AccordionHeader>
        <AccordionBody>
          <div>
            {msgs.map((msg, idx) => (
              <div className={`text-${level2color(msg.level)}`} key={idx}>
                [{msg.level}] {msg.msg}
              </div>
            ))}
          </div>
          {devices.length > 0 && (
            <table className="table table-sm mt-1">
              <thead>
                <tr>
                  {['Primary', 'Id', 'ManId', 'Ver', 'Medium'].map(
                    (colName, idx) => (
                      <th
                        scope="col"
                        key={idx}
                        className={idx != 0 ? 'text-end' : ''}
                      >
                        {colName}
                      </th>
                    )
                  )}
                  {['Raw', 'Parsed'].map((btnColName, idx) => (
                    <th scope="col" key={idx} className="text-center">
                      {btnColName}
                    </th>
                  ))}
                </tr>
              </thead>
              {devicesSorted.map((devInfo, idx) => (
                <MBusScannerResultRow devInfo={devInfo} key={idx} />
              ))}
            </table>
          )}
        </AccordionBody>
      </AccordionItem>
    )
  }
}
